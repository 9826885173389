@import "../../theme/PrimaryColors.scss";
@import "../../theme/Fonts.scss";



.gameScore {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Caption;
  color: $textColor;
  gap: 15px;

  .score {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    width: 100px;
    height: 70px;
    background-image: url("../../data/images/square.png");
    background-size: 100px 70px;
  }

  .time {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 60px;
    font-family: Caption;
    font-size: 40px;
    background-image: url("../../data/images/timer_frame.png");
    background-size: 170px 60px;
  }

  .user-avatar {
    width: 100px;
    height: 100px;
    font-family: Caption;
    font-size: 40px;
    background-image: url("../../data/images/user.png");
    background-size: 100px 100px;
  }

  .user-name {
    text-align: center;
    font-size: 30px;
    color: black;
  }
}

.World_container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px
}

.World {
  width: 1000px !important;
  height: 640px !important;
  outline: none;
  background-image: url("../../data/images/world-back.png");
  background-size: 1000px 640px;
}

.gameOverContainer {
  color: black;
  font-family: Caption;
  font-size: 80px;

  .gameOver-back {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 800px;
    background-image: url("../../data/images/paper_sheet.png");
    height: 600px;
    background-size: 800px 600px;

    .gameOverElement {
      margin-top: 20px;
      width: 650px;
      height: 382px;
      background-size: 650px 382px;
      font-size: 40px;
    }
  }
}