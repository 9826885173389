@import "./theme/PrimaryColors.scss";

body {
  margin: 0;
  background-size: 100% 100%;
  color: $textColor;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}