@import url(https://fonts.googleapis.com/css?family=Mr+Dafoe);

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  margin: 0;
  overflow: hidden;
  cursor: none;

  :hover {
    cursor: none;
  }
}

.cursor-style {
  background-image: url("./data/images/cursor.png");
  background-position: center;
  background-size: contain;
  height: 3rem;
  width: 3rem;
  position: fixed;
  pointer-events: none;
  z-index: 10000;
}



.header {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  margin: 10px 30px;
}

.header_menu {
  display: flex;
  gap: 20px;
}

.header .button {
  background: transparent;
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
  border: 0;
}

.header .button img {
  width: 50px;
  height: 50px;
}

.header .button img:hover {
  filter: hue-rotate(180deg);
}