@font-face {
    font-family: 'Caption';
    src: local('Caption'), url("../fonts/American Captain.otf") format('opentype');
}

@font-face {
    font-family: 'Baby';
    src: local('Caption'), url("../fonts/Baby\ Doll.otf") format('opentype');
}

.bangerFont {
    text-transform: uppercase;
    font-family: "Encode Sans Expanded", cursive !important;
    z-index: 1000;
    white-space: nowrap;
    font-weight: normal;
    color: $textColor;
}

.CaptionFont {
    font-family: Caption;
}

.BabyFont {
    font-family: Baby;
}